import { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import AddressFinder, {
  defaultValues as addressFinderDefaultValues,
  addressFinderSchema,
} from '@components/AddressFinder/AddressFinder';
import { useAddressFinderQueryStringDefaultValues } from '@components/AddressFinder/hooks/useAddressFinderQueryStringDefaultValues';
import { Link } from '@dxp-next';
import { useRedirect } from '@hooks/redirect';
import { AddressFinderRendering } from '@sitecore/types/manual/AddressFinder';
import { Box, Button, ButtonLink, Form, Stack, Text, TextLink } from '@sparky';

const linkExists = (link?: {
  value: LinkFieldValue & { href: string; linktype: string };
  editable?: string;
}): boolean => {
  return !!link?.value?.href || !!link?.editable;
};
interface FormProps {
  city?: string | undefined;
  houseNumber?: string;
  houseNumberSuffix?: string;
  postalCode?: string;
  street?: string | undefined;
}

const AddressFinderForm: FC<AddressFinderRendering> = ({ fields }) => {
  const addressFinderQueryStringDefaultValues = useAddressFinderQueryStringDefaultValues();
  const redirect = useRedirect();
  const [isSuffixRequired, setIsSuffixRequired] = useState(false);
  const { buttonLink, secondaryButtonLink, secondaryLink, disclaimerText, addressGroupLabel } = fields;

  const formSchema = yup.object({
    ...addressFinderSchema(isSuffixRequired).fields,
  });

  const formMethods = useForm({
    defaultValues: {
      ...addressFinderDefaultValues,
      ...addressFinderQueryStringDefaultValues,
    },
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });

  const { handleSubmit } = formMethods;

  const onSubmit = ({ houseNumber, postalCode, houseNumberSuffix }: FormProps) => {
    redirect({
      queryParams: { postalCode: postalCode?.replace?.(' ', ''), houseNumber, houseNumberSuffix },
      link: buttonLink?.value,
    });
  };

  const customLabels = {
    houseNumber: fields.houseNumberFormField,
    houseNumberSuffix: fields.houseNumberSuffixFormField,
    postalCode: fields.postalCodeFormField,
    notFoundErrorNotification: fields.notFoundErrorNotification,
    serverErrorNotification: fields.serverErrorNotification,
  };

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <AddressFinder
          customLabels={customLabels}
          isSuffixRequired={isSuffixRequired}
          setIsSuffixRequired={setIsSuffixRequired}
          addressGroupLabel={addressGroupLabel?.value ?? 'Adres invoer'}
        />

        {(disclaimerText?.value || disclaimerText?.editable) && (
          <Box paddingTop="4">
            <Text color="textLowEmphasis">{disclaimerText.value}</Text>
          </Box>
        )}

        {linkExists(buttonLink) && (
          <Box paddingTop="6">
            <Stack
              gap="4"
              alignY="center"
              direction={{ initial: 'column', lg: 'row' }}
              inline={{ initial: false, lg: true }}
              wrap>
              <Button size="regular" type="submit">
                {buttonLink?.value.text}
              </Button>
              {linkExists(secondaryButtonLink) && (
                <ButtonLink href={secondaryButtonLink?.value.href} size="regular" action="secondary">
                  {secondaryButtonLink?.value.text}
                </ButtonLink>
              )}
              {!linkExists(secondaryButtonLink) && linkExists(secondaryLink) && (
                <Link linkType={secondaryLink?.value.linktype ?? 'internal'} linkValue={secondaryLink?.value ?? {}}>
                  <TextLink emphasis="high" href={secondaryLink?.value.href}>
                    {secondaryLink?.value.text}
                  </TextLink>
                </Link>
              )}
            </Stack>
          </Box>
        )}
      </Form>
    </FormProvider>
  );
};

export default AddressFinderForm;
