import { ComponentProps, FC, useEffect } from 'react';
import { Dispatch, SetStateAction } from 'react';

import { useWatch, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import postalCodeRegex from '@common/validation/postalCodeRegex';
import { ApiError } from '@dc/client/ApiError';
import { useAddressFormWatcher } from '@hooks/address';
import { I18nProvider, useTranslation } from '@i18n';
import { Customers_Address_AddressModel } from '@monorepo-types/dc';

import { AddressFinderProvider, useAddressFinder } from './AddressFinderContext';
import { AddressFinderPrivate } from './AddressFinderPrivate';
import { AddressFinderPublic } from './AddressFinderPublic';

interface Props
  extends Pick<
    ComponentProps<typeof AddressFinderPublic>,
    'customLabels' | 'isSuffixRequired' | 'setIsSuffixRequired' | 'setIsLoading'
  > {
  isPrivate?: boolean;
  setErrorData?: Dispatch<SetStateAction<ApiError | undefined>>;
  setAddressFinderData?: Dispatch<SetStateAction<Customers_Address_AddressModel | undefined>>;
  customErrorNotification?: React.ReactElement;
  hasAutocomplete?: boolean;
  addressGroupLabel?: string;
}

export const defaultValues = {
  city: '',
  houseNumber: '',
  houseNumberSuffix: '',
  postalCode: '',
  street: '',
};

export const addressFinderSchema = (isSuffixRequired: boolean) =>
  yup.object({
    ...(isSuffixRequired && { houseNumberSuffix: yup.string().required('houseNumberSuffixInvalid') }),
    city: yup.string().required(),
    houseNumber: yup
      .string()
      .required()
      .test(value => !!value && +value > 0),
    postalCode: yup.string().required().matches(postalCodeRegex),
    street: yup.string().required(),
  });

const AddressFinderComponent: FC<Props> = ({
  customLabels,
  isPrivate,
  isSuffixRequired,
  setIsSuffixRequired,
  setErrorData,
  setAddressFinderData,
  setIsLoading,
  customErrorNotification,
  hasAutocomplete = true,
  addressGroupLabel = 'Adres invoer',
}) => {
  const { isLoading: labelsAreLoading } = useTranslation();
  const { addressData, setAddressData } = useAddressFinder();
  const { control, resetField, setValue, clearErrors } = useFormContext();
  const { houseNumber, houseNumberSuffix: currentSelectedHouseNumberSuffix, postalCode } = useWatch({ control });

  const { emptySuffixString, houseNumberSuffix, parameters, isValidInput } = useAddressFormWatcher({
    houseNumber,
    houseNumberSuffix: currentSelectedHouseNumberSuffix,
    postalCode,
  });

  /*
    Check whether postalCode and houseNumber are prefilled by default
    and check if the address is valid, otherwise clear the values
  */
  useEffect(() => {
    if (postalCode && houseNumber && !isValidInput) {
      setValue('postalCode', '');
      setValue('houseNumber', '');
    }
  }, []);

  useEffect(() => {
    if (houseNumberSuffix) {
      clearErrors();
      setValue('houseNumberSuffix', houseNumberSuffix.toUpperCase());
      setAddressData(prevState => ({ ...prevState, houseNumberSuffix }));
    }
  }, [houseNumberSuffix, setAddressData]);

  useEffect(() => {
    if (!postalCode || !houseNumber || (houseNumber && postalCode)) {
      resetField('houseNumberSuffix', { defaultValue: undefined });
    }
  }, [postalCode, houseNumber, resetField]);

  useEffect(() => {
    if (houseNumber && postalCode) {
      clearErrors();
      setValue('city', undefined);
      setValue('street', undefined);
    }
  }, [houseNumber, postalCode, setValue]);

  useEffect(() => {
    setValue('city', addressData?.city);
    setValue('street', addressData?.street);
  }, [addressData, setValue]);

  useEffect(() => {
    if (!setAddressFinderData) return;
    setAddressFinderData(addressData);
  }, [addressData]);

  const params = {
    currentSelectedHouseNumberSuffix,
    customLabels,
    emptySuffixString,
    isSuffixRequired,
    setErrorData,
    parameters: parameters
      ? {
          houseNumber: parameters.houseNumber,
          houseNumberSuffix: parameters.houseNumberSuffix,
          postalCode: parameters.postalCode.replace(' ', ''),
        }
      : null,
    resetField,
    setIsSuffixRequired,
    customErrorNotification,
    hasAutocomplete,
    setIsLoading,
    addressGroupLabel,
  };

  if (labelsAreLoading) return null;

  return isPrivate ? <AddressFinderPrivate {...params} /> : <AddressFinderPublic {...params} />;
};

const AddressFinder: FC<Props> = ({
  customLabels,
  isPrivate,
  isSuffixRequired,
  setIsSuffixRequired,
  setErrorData,
  setAddressFinderData,
  customErrorNotification,
  hasAutocomplete,
  setIsLoading,
  addressGroupLabel,
}) => (
  <I18nProvider dictionary={locale => import(`./content/${locale}.json`)}>
    <AddressFinderProvider>
      <AddressFinderComponent
        customErrorNotification={customErrorNotification}
        customLabels={customLabels}
        isPrivate={isPrivate}
        isSuffixRequired={isSuffixRequired}
        setIsSuffixRequired={setIsSuffixRequired}
        setErrorData={setErrorData}
        setAddressFinderData={setAddressFinderData}
        hasAutocomplete={hasAutocomplete}
        setIsLoading={setIsLoading}
        addressGroupLabel={addressGroupLabel}
      />
    </AddressFinderProvider>
  </I18nProvider>
);

export default AddressFinder;
